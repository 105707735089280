<template>
    <template-base>
        <div class="d-flex justify-content-between">
            <h1 class="align-self-center">Logs</h1>
        </div>
        <DataTable scroll noedit :async="pagination" :loading="loading" :colunas="cols" :linhas="linhas" :errMsg="errMsg" :state="dataTableState" @state-change="setStateDataTable">
            <template #Nome="{value}">
                    <router-link
                        v-if="value"
                        :to="{ name: 'edit_usuario', params: { id: value.id }}"
                        title="Ir para a edição"
                    ><edit-icon size="16" /> {{value.nome}}</router-link>
                    <span v-else>-</span>
            </template>
            <template #Status="{value}">
                    <span v-if="value === 'Logout realizado pelo botão de saída do menu'"><check-icon class="text-success" /> {{ value }}</span>
                    <span v-else-if="value"><x-icon class="text-danger" /> {{ value }}</span>
                    <span v-else><check-icon class="text-success" /> Sucesso </span>
            </template>
            <template #Tipo="{value}">
                    <span v-if="value == true"> Login </span>
                    <span v-else> Logout </span>
            </template>
            <template #results-page v-if="pagination">
                <select class="custom-select ml-1" :value="results" @input="changeResults" data-cy="resultados-pagina" >
                    <option value="10">10 por página</option>
                    <option value="20">20 por página</option>
                    <option value="50">50 por página</option>
                    <option value="100">100 por página</option>
                </select>
            </template>
        </DataTable>
        <Paginator :page="page" :pages="pages" :count="count" :disabled="loading" :first="first" :last="last" @paginate="paginate" />
    </template-base>
</template>

<style scoped>
    .custom-select {
        width: auto;
    }
    .paginador {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
import DataTable from '@/components/DataTable'
import TemplateBase from '@/templates/Base'
import Paginator from '@/components/Paginator'
import axios from 'axios'
import api from '@/api'

export default {
    components: {
        DataTable,
        TemplateBase,
        Paginator,
    },
    data () {
        return {
            cols: ['#','Nome', 'Email', 'Data', 'Tipo', 'Status'],
            colsName: ['id', 'idUsuario', 'email', 'dataHora', 'login', 'erro'],
            lista: [],
            loading: false,
            errMsg: '',
            pagination: false,
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
            firstLoad: true,
        };
    },
    computed: {
        dataTableState () {
            if (this.firstLoad) {
                return { sortBy: 3, sortAsc: false, query: '' }
            }
            return this.$store.state.dataTables['dataTableLogLogin'];
        },
        linhas () {
            return this.lista.map(login => ({
                cols: [login.id, login.usuario && { id: login.idUsuario, nome: login.usuario.nome }, login.email, (new Date(login.dataHora)).toLocaleString(), login.login, login.erro],
            }));
        },
    },
    methods: {
        formatter(data) {
            return data.split('-').reverse().join('/');
        },
        setStateDataTable (...args) {
            this.$store.commit('dataTables/setStateDataTableLogLogin', ...args);
            this.page = 0;
            this.firstLoad = false;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },
        update (page = 1, results = 20) {
            this.loading = true;
            let url;
            this.pagination = true;
            url = api.v1.logs.login(page, results, this.dataTableState.query, this.colsName[this.dataTableState.sortBy], this.dataTableState.sortAsc);

            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = res.data.rows || res.data;
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
    },
    created () {
        this.update();
    }
}
</script>
